import React from 'react'
import Breadcrumb from '../components/breadcrumb'

import Layout from '../components/layout'
import SEO from '../components/seo'
import SectorPage from '../components/sectorPage'

const CarPage = ({location}) => {
  return (
    <Layout simpleHeader={true} url="/automobile" type="sector">
      <SEO 
        title="Classement des meilleurs sites de constructeurs automobiles"
        description="Quels sont les meilleurs sites de constructeurs automobiles de l'Observatoire de l'impact positif ? Voici les résultats pour Peugeot, Renault, Citroen, DS et Alpine."
        path="/automobile" />
      
      <Breadcrumb url="/automobile" label="Automobile" />

      <SectorPage slug="car" type="sector" />
    </Layout>
  )
  }

export default CarPage

